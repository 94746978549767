import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { successToast, errorToast, warningToast } from 'utils/toastUtils';
import BeSafeSwitch from 'components/commons/BeSafeSwitch';
import NetworksSelect from './NetworksSelect';
import { createVpnTunnel, updateVpnTunnel } from 'app/crud/vpn_tunnels.crud';
import { isPortValid } from 'utils/utils';
import BeSafeControlFeedback from 'components/commons/BeSafeControlFeedback';
import BeSafeSeparator from 'components/commons/BeSafeSeparator';
import ApplicationSelect from './ApplicationSelect';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import BeSafeButtonGroup from 'components/commons/BeSafeButtonGroup';
import CertificateSelect from 'components/commons/CertificateSelect';
import { isFQDN, isIP } from 'validator';
import { getCertificate } from 'app/crud/certificates.crud';
import InterfaceSelect from './InterfaceSelect';

const ManageTunnelModal = (props) => {
  const intl = useIntl();
  const [certificate, setCertificate] = useState(null);

  useEffect(() => {
    if (props.editMode && props.selectedTunnel.certificate_id) {
      getCertificate(props.selectedTunnel.certificate_id)
        .then((response) => {
          let certificate = response.data.certificate;

          if (certificate) {
            setCertificate(certificate);
          }
        })
        .catch((err) => {
          errorToast({
            body: 'SSL_INSPECTION.CERTIFICATE_ERROR_FETCH',
            intl: intl,
          });
        });
    }
  }, [props.editMode, props.selectedTunnel?.certificate_id, intl]);

  const getIpPoolValue = (tunnel) => {
    if (tunnel.source_object) {
      return [tunnel.source_object];
    }

    return [];
  };

  const getUsers = () => {
    return props.selectedTunnel.users;
  };

  const getRoutingAddresses = () => {
    return props.selectedTunnel.addresses;
  };

  const getApplications = (routing_applications) => {
    return routing_applications.map((item) => {
      return {
        id: item['application']['id'],
        name: item['application']['name'],
        family: item['application']['family'],
        description: item['application']['description'],
      };
    });
  };

  const getPublicServerType = (tunnel, profileType, edit) => {
    if (edit && tunnel && isIP(tunnel.public_server)) return 'ip';
    if (edit && tunnel) return 'hostname';
    if (profileType === 'cloud') return 'hostname';
    return 'ip';
  };

  const getPublicServer = (tunnel, profileType, sase_hostname, edit) => {
    if (edit && tunnel) return tunnel.public_server;
    if (profileType === 'cloud') return sase_hostname;
    return '';
  };

  const parseInterfaces = (interfaces) => {
    if (Array.isArray(interfaces)) return interfaces;
    if (interfaces) return interfaces.split(',').map((i) => i.trim());
    return [];
  };

  const initialValues = {
    name: props.editMode ? props.selectedTunnel.name : '',
    port: props.editMode ? props.selectedTunnel.port.toString() : '1194',
    protocol: props.editMode ? props.selectedTunnel.protocol : 'udp',
    authentication_method: props.editMode
      ? props.selectedTunnel.authentication_method
      : 'users',
    enable_split_tunneling: props.editMode
      ? props.selectedTunnel.enable_split_tunneling
      : false,
    dns_1: props.editMode ? props.selectedTunnel.dns_1 : '',
    dns_2: props.editMode ? props.selectedTunnel.dns_2 : '',
    interfaces: props.editMode
      ? parseInterfaces(props.selectedTunnel.interfaces)
      : [],
    certificate_id: props.editMode ? props.selectedTunnel.certificate_id : '',
    ip_pool: props.editMode ? getIpPoolValue(props.selectedTunnel) : [],
    users: props.editMode ? getUsers() : [],
    routing_address: props.editMode ? getRoutingAddresses() : [],
    dns_suffix: props.editMode ? props.selectedTunnel.dns_suffix || '' : '',
    routing_applications: props.editMode
      ? getApplications(props.selectedTunnel.applications)
      : [],
    public_server_type: getPublicServerType(
      props.selectedTunnel,
      props.profileType,
      props.editMode
    ),
    public_server: getPublicServer(
      props.selectedTunnel,
      props.profileType,
      props.saseHostname,
      props.editMode
    ),
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      errors.name = intl.formatMessage({
        id: 'GENERAL.REQUIRED_FIELD',
      });
    }

    if (props.profileType !== 'cloud') {
      if (!values.port || !values.port.trim()) {
        errors.port = intl.formatMessage({
          id: 'GENERAL.REQUIRED_FIELD',
        });
      } else if (!isPortValid(values.port)) {
        errors.port = intl.formatMessage({
          id: 'VPNTUNNEL.INVALID_PORT',
        });
      }
    }

    if (values.ip_pool.length === 0) {
      errors.ip_pool = intl.formatMessage({
        id: 'GENERAL.REQUIRED_FIELD',
      });
    }

    if (values.dns_1 && !isIP(values.dns_1)) {
      errors.dns_1 = intl.formatMessage({
        id: 'VPNTUNNEL.INVALID_DNS',
      });
    }

    if (values.dns_2 && !isIP(values.dns_2)) {
      errors.dns_2 = intl.formatMessage({
        id: 'VPNTUNNEL.INVALID_DNS',
      });
    }

    if (values.public_server_type === 'ip' && !isIP(values.public_server)) {
      errors.public_server = intl.formatMessage({
        id: 'VPNTUNNEL.INVALID_IP',
      });
    }

    if (
      values.public_server_type === 'hostname' &&
      !isFQDN(values.public_server)
    ) {
      errors.public_server = intl.formatMessage({
        id: 'VPNTUNNEL.INVALID_HOSTNAME',
      });
    }

    if (values.authentication_method === 'users' && values.users.length === 0) {
      errors.users = intl.formatMessage({
        id: 'GENERAL.REQUIRED_FIELD',
      });
    }

    if (
      values.authentication_method === 'client_cert' &&
      !values.certificate_id
    ) {
      errors.certificate_id = intl.formatMessage({
        id: 'GENERAL.REQUIRED_FIELD',
      });
    }

    if (values.enable_split_tunneling) {
      if (values.routing_address.length === 0) {
        errors.routing_address = intl.formatMessage({
          id: 'GENERAL.REQUIRED_FIELD',
        });
      } else if (
        values.ip_pool.some((item1) =>
          values.routing_address.some((item2) => item1.target === item2.target)
        )
      ) {
        errors.routing_address = intl.formatMessage({
          id: 'VPNTUNNEL.IP_POOL_ROUTING_ADDRESS_ERROR',
        });
      }
    }

    return errors;
  };

  const onHide = () => {
    props.onHide();
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (props.editMode) {
      editTunnel(values, setSubmitting);
      return;
    }

    addTunnel(values, setSubmitting);
  };

  const getApplicationsToUpdate = (currentApplications, form_values) => {
    const newApplications = form_values.routing_applications;
    if (form_values.enable_split_tunneling === true) {
      const applicationToDelete = currentApplications
        .filter(
          (currentApplications) =>
            newApplications.findIndex(
              (newApplications) =>
                newApplications.id === currentApplications.application.id
            ) === -1
        )
        .map((currentApplications) => {
          return {
            relation_id: currentApplications.id,
            _destroy: true,
            id: currentApplications.application.id,
          };
        });

      const applicationToUpdate = newApplications.map((newApplication) => {
        currentApplications.some((currentApplication) => {
          if (newApplication.id === currentApplication.application.id) {
            newApplication.relation_id = currentApplication.id;
            return true;
          }
          return false;
        });
        return newApplication;
      });

      return applicationToDelete
        .concat(applicationToUpdate)
        .map((application) => {
          return {
            application_id: application.id,
            _destroy: application._destroy,
            id: application.relation_id,
          };
        });
    } else {
      const applicationToDelete = currentApplications.map(
        (currentApplications) => {
          return {
            relation_id: currentApplications.id,
            _destroy: true,
            id: currentApplications.application.id,
          };
        }
      );
      return applicationToDelete.map((application) => {
        return {
          application_id: application.id,
          _destroy: application._destroy,
          id: application.relation_id,
        };
      });
    }
  };

  const getRoutingApplications = (routing_applications) => {
    return routing_applications.map((application) => {
      return { application_id: application.id };
    });
  };

  const editTunnel = (values, setSubmitting) => {
    setSubmitting(true);
    const vpnTunnel = {
      vpn_tunnel: {
        name: values.name,
        enable_split_tunneling: values.enable_split_tunneling,
        dns_1: values.dns_1,
        dns_2: values.dns_2,
        dns_suffix: values.dns_suffix,
        users: values.users,
        source_object_id: Object.keys(values.ip_pool[0]).includes('object_type')
          ? values.ip_pool[0].id
          : null,
        routing_address: values.enable_split_tunneling
          ? values.routing_address
          : [],
        vpn_tunnels_applications_attributes: getApplicationsToUpdate(
          props.selectedTunnel.applications,
          values
        ),
        public_server: values.public_server,
        authentication_method: values.authentication_method,
        ...(props.type === 'ipsec' && {
          interfaces: Array.isArray(values.interfaces)
            ? values.interfaces.join(',')
            : values.interfaces || '',
        }),
      },
    };

    if (values.authentication_method === 'client_cert') {
      vpnTunnel.vpn_tunnel.certificate_id = values.certificate_id;
    }

    if (values.authentication_method === 'users') {
      vpnTunnel.vpn_tunnel.certificate_id = null;
      setCertificate(null);
    }

    if (props.profileType !== 'cloud') {
      vpnTunnel.vpn_tunnel.port = parseInt(values.port);
    }

    updateVpnTunnel(props.profileId, props.selectedTunnel.id, vpnTunnel)
      .then((response) => {
        successToast({ body: 'VPNTUNNEL.SUCCESS_UPDATE', intl: intl });
        onHide();
        props.onUpdate(response.data);
      })
      .catch((error) => {
        if (error.response.data.error) {
          errorToast({ body: error.response.data.error, intl: intl });
        } else if (error.response.data.includes('VPNTUNNEL.')) {
          errorToast({ body: error.response.data, intl: intl });
        } else {
          errorToast({ body: 'VPNTUNNEL.ERROR_UPDATE', intl: intl });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const addTunnel = (values, setSubmitting) => {
    setSubmitting(true);

    const vpnTunnel = {
      vpn_tunnel: {
        name: values.name,
        enable_split_tunneling: values.enable_split_tunneling,
        dns_1: values.dns_1,
        dns_2: values.dns_2,
        dns_suffix: values.dns_suffix,
        users: values.users,
        source_object_id: Object.keys(values.ip_pool[0]).includes('object_type')
          ? values.ip_pool[0].id
          : null,
        routing_address: values.routing_address,
        vpn_tunnels_applications_attributes: getRoutingApplications(
          values.routing_applications
        ),
        tunnel_type: props.type,
        public_server: values.public_server,
        protocol: values.protocol,
        authentication_method: values.authentication_method,
        ...(props.type === 'ipsec' && {
          interfaces: Array.isArray(values.interfaces)
            ? values.interfaces.join(',')
            : values.interfaces || '',
        }),
      },
    };

    if (values.authentication_method === 'client_cert') {
      vpnTunnel.vpn_tunnel.certificate_id = values.certificate_id;
    }

    if (props.profileType !== 'cloud') {
      vpnTunnel.vpn_tunnel.port = parseInt(values.port);
    }

    createVpnTunnel(props.profileId, vpnTunnel)
      .then(() => {
        successToast({ body: 'VPNTUNNEL.SUCCESS_CREATE', intl: intl });
        if (props.profileType === 'utm') {
          warningToast({
            body: 'VPNTUNNEL.WARNING_CREATE',
            data: {
              profileName: props.profileName,
            },
            intl: intl,
            hideAfter: 15,
          });
        }
        onHide();
        props.onCreate();
      })
      .catch((error) => {
        if (error.response.data.error) {
          errorToast({ body: error.response.data.error, intl: intl });
        } else if (error.response.data.includes('VPNTUNNEL.')) {
          errorToast({
            body: JSON.stringify(error.response.data.error),
            intl: intl,
          });
        } else {
          errorToast({ body: 'VPNTUNNEL.ERROR_CREATE', intl: intl });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal show={props.showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editMode === true ? (
            <FormattedMessage id="VPNTUNNEL.EDIT_TUNNEL" />
          ) : (
            <FormattedMessage id="VPNTUNNEL.ADD_TUNNEL" />
          )}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateForm}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label>
                      <FormattedMessage id="GENERAL.NAME" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      className="border-secondary"
                      onChange={handleChange}
                      isInvalid={touched.name && errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <span className="text-primary text-uppercase">
                <FormattedMessage id="VPNTUNNEL.SERVER_DEFINITION" />
              </span>
              <BeSafeSeparator />
              <Row>
                <Col md={5}>
                  <Form.Group>
                    <Form.Label className="align-bottom">
                      <FormattedMessage
                        id={
                          props.type === 'ipsec'
                            ? 'VPNTUNNEL.DESTINATION_SERVER_IPSEC'
                            : 'VPNTUNNEL.DESTINATION_SERVER_SSL'
                        }
                      />
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="border-secondary"
                      value={values.public_server_type}
                      name="public_server_type"
                      onChange={handleChange}
                      disabled={props.profileType === 'cloud'}
                    >
                      <option value="ip">IP</option>
                      <option value="hostname">Hostname</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group controlId="public_server">
                    <Form.Label>
                      <FormattedMessage
                        id={`VPNTUNNEL.${values.public_server_type?.toUpperCase()}`}
                      />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="public_server"
                      value={values.public_server}
                      className="border-secondary"
                      onChange={handleChange}
                      disabled={props.profileType === 'cloud'}
                      isInvalid={touched.public_server && errors.public_server}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.public_server}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <BeSafeContainer visible={props.profileType !== 'cloud'}>
                <span className="text-primary text-uppercase">
                  <FormattedMessage id="VPNTUNNEL.CONNECTION_SETTINGS" />
                </span>
                <BeSafeSeparator />
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="port">
                      <Form.Label>
                        <FormattedMessage id="VPNTUNNEL.PORT" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="port"
                        value={values.port}
                        className="border-secondary"
                        onChange={handleChange}
                        isInvalid={touched.port && errors.port}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.port}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="protocol">
                      <Form.Label>
                        <FormattedMessage id="VPNTUNNEL.PROTOCOL" />
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="protocol"
                        value={values.protocol}
                        className="border-secondary"
                        onChange={handleChange}
                      >
                        <option value="tcp">TCP</option>
                        <option value="udp">UDP</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                {props.type === 'ipsec' && (
                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId="interfaces">
                        <Form.Label>
                          <FormattedMessage id="VPNTUNNEL.INTERFACES" />
                        </Form.Label>
                        <InterfaceSelect
                          handleChange={(val) =>
                            setFieldValue('interfaces', val)
                          }
                          initialTags={values.interfaces}
                          defaultTagIfEmpty
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </BeSafeContainer>
              <span className="text-primary text-uppercase">
                <FormattedMessage id="VPNTUNNEL.TUNNEL_AUTHENTICATION" />
              </span>
              <BeSafeSeparator />
              <Row>
                <Col md={6}>
                  <Form.Group controlId="enable_split_tunneling">
                    <Form.Label className="mr-3">
                      <FormattedMessage id="VPNTUNNEL.ENABLE_SPLIT_TUNNELING" />
                    </Form.Label>
                    <BeSafeSwitch
                      state={values.enable_split_tunneling}
                      name="enable_split_tunneling"
                      onChange={(val) => {
                        setFieldValue('enable_split_tunneling', val);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {values.enable_split_tunneling && (
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="routing_address">
                      <Form.Label>
                        <FormattedMessage id="VPNTUNNEL.ROUTING_ADDRESS" />
                      </Form.Label>
                      <NetworksSelect
                        handleChange={(val) =>
                          setFieldValue(
                            'routing_address',
                            val.map((v) => v)
                          )
                        }
                        selectType="source"
                        allowedNetworks={['network', 'network-group']}
                        initialTags={values.routing_address}
                        ipProtocol="ipv4"
                      />
                      <BeSafeControlFeedback>
                        {errors.routing_address}
                      </BeSafeControlFeedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="d-none">
                    <Form.Group controlId="applications">
                      <Form.Label>
                        <FormattedMessage id="GENERAL.APPLICATIONS" />
                      </Form.Label>
                      <ApplicationSelect
                        handleChange={(val) =>
                          setFieldValue(
                            'routing_applications',
                            val.map((v) => v)
                          )
                        }
                        selectType="source"
                        initialTags={values.routing_applications}
                        profileId={props.profileId}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="ip_pool">
                    <Form.Label>
                      <FormattedMessage id="VPNTUNNEL.SOURCE" />
                    </Form.Label>
                    <NetworksSelect
                      handleChange={(val) =>
                        setFieldValue(
                          'ip_pool',
                          val.map((v) => v)
                        )
                      }
                      selectType="source"
                      allowedNetworks={['network']}
                      initialTags={values.ip_pool}
                      ipProtocol="ipv4"
                      limit={1}
                    />
                    {errors.ip_pool && (
                      <BeSafeControlFeedback>
                        {errors.ip_pool}
                      </BeSafeControlFeedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {props.type === 'ipsec' && (
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="dns_suffix">
                      <Form.Label>
                        <FormattedMessage id="VPNTUNNEL.DNS_SUFFIX" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dns_suffix"
                        value={values.dns_suffix}
                        className="border-secondary"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <Form.Group controlId="authentication">
                    <Form.Label>
                      <FormattedMessage id="SSLVPN.USER_LIST" />
                    </Form.Label>
                    <NetworksSelect
                      handleChange={(val) =>
                        setFieldValue(
                          'users',
                          val.map((v) => v)
                        )
                      }
                      selectType="source"
                      allowedNetworks={['user', 'user-group']}
                      initialTags={values.users}
                      ipProtocol="both"
                    />
                    {errors.users && (
                      <BeSafeControlFeedback>
                        {errors.users}
                      </BeSafeControlFeedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Label>
                    <FormattedMessage id="SSLVPN.AUTHENTICATION" />
                  </Form.Label>
                  <BeSafeButtonGroup
                    name="authentication_method"
                    groupPrefix={'SSLVPN'}
                    groups={['client_cert', 'users']}
                    checked={values.authentication_method}
                    onChange={(val) =>
                      setFieldValue('authentication_method', val)
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <BeSafeContainer
                  visible={values.authentication_method === 'client_cert'}
                >
                  <Col md={12}>
                    <Form.Group controlId="client_cert">
                      <CertificateSelect
                        handleChange={(val) => {
                          setFieldValue('certificate_id', val.id);
                          setCertificate(val);
                        }}
                        initialTag={props.editMode ? certificate : null}
                      />
                      {errors.certificate_id && (
                        <BeSafeControlFeedback>
                          {errors.certificate_id}
                        </BeSafeControlFeedback>
                      )}
                    </Form.Group>
                  </Col>
                </BeSafeContainer>
              </Row>
              <span className="text-primary text-uppercase">
                <FormattedMessage id="VPNTUNNEL.DNS" />
              </span>
              <BeSafeSeparator />
              <Row>
                <Col md={6}>
                  <Form.Group controlId="dns_1">
                    <Form.Label>
                      <FormattedMessage id="VPNTUNNEL.DNS1" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="dns_1"
                      value={values.dns_1}
                      className="border-secondary"
                      onChange={handleChange}
                      isInvalid={touched.dns_1 && errors.dns_1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dns_1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="dns_2">
                    <Form.Label>
                      <FormattedMessage id="VPNTUNNEL.DNS2" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="dns_2"
                      value={values.dns_2}
                      className="border-secondary"
                      onChange={handleChange}
                      isInvalid={touched.dns_2 && errors.dns_2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dns_2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                <i className="fas fa-check mr-2" />
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ManageTunnelModal;
