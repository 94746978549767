import { findMainConfigurations } from 'app/crud/configuration.crud';
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const SidebarDataContext = createContext();

const SidebarDataProvider = ({ children }) => {
  const [sidebarData, setSidebarData] = useState([]);

  const { accountType, latestConfigurationChange } = useSelector(
    ({ auth, configuration }) => ({
      accountType: auth.user.account_type,
      latestConfigurationChange: configuration.latestConfigurationChange,
    }),
    shallowEqual
  );

  const fetchConfigurationMenuData = useCallback(async () => {
    if (accountType !== null && accountType !== 'reseller') {
      try {
        const response = await findMainConfigurations();
        setSidebarData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  }, [accountType, latestConfigurationChange]);

  const refreshData = () => {
    fetchConfigurationMenuData();
  };

  useEffect(() => {
    fetchConfigurationMenuData();
  }, [fetchConfigurationMenuData]);

  return (
    <SidebarDataContext.Provider value={{ sidebarData, refreshData }}>
      {children}
    </SidebarDataContext.Provider>
  );
};

export { SidebarDataContext, SidebarDataProvider };
