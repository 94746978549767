import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const MenuAnalytic = ({ accountType, role }) => {
  const location = useLocation();

  const menuElements = [
    {
      route: '/',
      msg: <FormattedMessage id="SIDEBAR.DASHBOARD" />,
      key: 'dashboard',
      allowed: {
        carrier: [
          'super_admin',
          'account_manager',
          'end_user',
          'operator',
          'report_manager',
        ],
        utm: [
          'super_admin',
          'account_manager',
          'end_user',
          'operator',
          'report_manager',
        ],
        reseller: ['super_admin', 'reseller_admin', 'report_manager'],
      },
    },
    {
      route: '/reports',
      msg: <FormattedMessage id="SIDEBAR.LOGS_REPORTS" />,
      key: 'reports',
      allowed: {
        carrier: [
          'super_admin',
          'account_manager',
          'end_user',
          'operator',
          'report_manager',
        ],
        utm: [
          'super_admin',
          'account_manager',
          'end_user',
          'operator',
          'report_manager',
        ],
        reseller: ['report_manager'],
      },
    },
    // Uncomment when XDR is activated
    // {
    //   route: '/xdr',
    //   msg: <FormattedMessage id="SIDEBAR.XDR" />,
    //   key: 'xdr',
    //   allowed: {
    //     carrier: [],
    //     utm: ['account_manager', 'end_user'],
    //   },
    // },
  ];

  const beAnalyzerLinks = {
    QA: 'https://qa.networkcloudmanager.com/web/beanalyzer/dashboard',
    PROD: 'https://teldat.cloudnetmanager.com/web/beanalyzer/dashboard',
  };

  const beSafeXDRLinks = {
    QA: 'https://visualizer-stress.cloudnetmanager.com',
    PROD: 'https://beanalyzer.teldat.com',
  };

  const isActiveMenu = (route) => {
    return location.pathname === route;
  };

  return (
    <>
      {menuElements
        .filter((elem) => elem.allowed?.[accountType || 'utm'].includes(role))
        .map((elem) => (
          <Link
            key={elem.key}
            id={`menu-${elem.key}`} // ID agregado dinámicamente
            className={`nav_option ${
              isActiveMenu(elem.route) ? 'is-active' : ''
            }`}
            to={elem.route}
          >
            {elem.msg}
          </Link>
        ))}

      <Link
        id="menu-be-analyzer"
        className="nav_option"
        to={
          process.env.NODE_ENV === 'production'
            ? beAnalyzerLinks.PROD
            : beAnalyzerLinks.QA
        }
        target="_blank"
      >
        be.Analyzer
        <OpenInNewIcon fontSize="small" />
      </Link>

      <Link
        id="menu-be-safe-xdr"
        className="nav_option"
        to={
          process.env.NODE_ENV === 'production'
            ? beSafeXDRLinks.PROD
            : beSafeXDRLinks.QA
        }
        target="_blank"
      >
        be.Safe XDR
        <OpenInNewIcon fontSize="small" />
      </Link>
    </>
  );
};

export default MenuAnalytic;
