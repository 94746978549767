import React, { useState } from 'react';
import { Add } from '@mui/icons-material';

import 'assets/scss/tags_select.scss';
import BeSafeButton from 'components/commons/BeSafeButton';
import InterfaceListSelect from 'components/networks/InterfaceListSelect';
import { useIntl } from 'react-intl';
import BeSafeSelectorTag from 'components/commons/BeSafeSelectorTag';

const InterfaceSelect = (props) => {
  const collectSelectedTags = (tags) => {
    if (Array.isArray(tags)) return tags;
    if (typeof tags === 'string') return tags.split(',').map((i) => i.trim());
    return [];
  };

  const selectedTags = collectSelectedTags(props.initialTags);

  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  const removeTag = (deletedTag) => {
    const newTags = selectedTags.filter((tag) => tag !== deletedTag);
    props.handleChange(newTags);
  };

  const handleItemsChange = (items) => {
    props.handleChange(items);
  };

  const getDisplayedSelectedTags = () => {
    let tags = collectSelectedTags(selectedTags);

    if (tags.length > 0) {
      return tags.map((tag) => ({
        name: tag,
        cancellable: props.disabled ? false : true,
      }));
    }

    if (props.defaultTagIfEmpty) {
      return [
        {
          name: intl.formatMessage({ id: 'GENERAL.ANY' }),
          cancellable: false,
        },
      ];
    }

    return [];
  };

  const displayedSelectedTags = getDisplayedSelectedTags();

  return (
    <>
      <div
        className={`d-flex ${props.disabled ? 'disabled' : ''}`}
        style={{ position: 'relative' }}
      >
        <div className="tags-select">
          <ul id="tags">
            {displayedSelectedTags.map((tag, index) => (
              <BeSafeSelectorTag
                key={index}
                index={tag.name}
                tag={tag}
                cancellable={'cancellable' in tag ? tag.cancellable : true}
                removeTags={removeTag}
              />
            ))}
          </ul>
        </div>
        <BeSafeButton
          className="btn-primary-transparent btn-in-box"
          onClick={() => {
            if (!props.disabled) {
              setShowModal(true);
            }
          }}
          icon={<Add />}
          variant="transparent"
        />
      </div>

      <InterfaceListSelect
        showModal={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        changeItems={handleItemsChange}
        initialSelected={selectedTags}
        deviceId={props.deviceId}
        limit={props.limit}
        ignoreBridge={props.ignoreBridge}
        onlyPhysical={props.onlyPhysical}
        onlyStatic={props.onlyStatic}
        disabledValues={props.disabledValues}
      />
    </>
  );
};

InterfaceSelect.defaultProps = {
  limit: 0,
};

export default InterfaceSelect;
