import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Table, Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import NoDataDisplay from 'components/commons/NoDataDisplay';
import TableLoader from 'components/commons/TableLoader';
import BeSafeButton from 'components/commons/BeSafeButton';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import { Lan } from '@mui/icons-material';
import { getInterfaces } from 'app/crud/accounts.crud';
import { getInterfaces as getDeviceInterfaces } from 'app/crud/devices.crud';

const InterfaceListSelect = (props) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelected || []
  );
  const [loading, setLoading] = useState(false);

  const selectItem = (item) => {
    if (!selectedItems.includes(item)) {
      if (props.limit === 0 || selectedItems.length < props.limit) {
        setSelectedItems((prev) => [...prev, item]);
      }
      return;
    }

    setSelectedItems([
      ...selectedItems.filter((selectedItem) => selectedItem !== item),
    ]);
  };

  const handleSubmit = () => {
    if (_.isFunction(props.changeItems)) {
      props.changeItems(selectedItems);
      handleHide();
    }
  };

  const handleHide = () => {
    setSelectedItems([]);
    props.onHide();
  };

  const interfacesFilters = (items, ignoreBridge, onlyPhysical, onlyStatic) => {
    if (ignoreBridge) {
      items = items.filter((iface) => !iface.name.includes('br'));
    }
    if (onlyPhysical) {
      items = items.filter((iface) => iface.interface_type !== 'openvpn');
    }
    if (onlyStatic) {
      items = items.filter((iface) => iface.dhcp === false && iface.value);
    }
    return items;
  };

  const fetchItems = useCallback(() => {
    setLoading(true);
    if (!props.deviceId) {
      getInterfaces()
        .then((response) => {
          let items = response.data.interfaces
            .filter((iface, i, arr) => arr.indexOf(iface) === i)
            .sort();
          items = interfacesFilters(
            items,
            props.ignoreBridge,
            props.onlyPhysical,
            props.onlyStatic
          );
          setItems(items.map((item) => item.name));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getDeviceInterfaces(props.deviceId)
        .then((response) => {
          let items = interfacesFilters(
            response.data.interfaces,
            props.ignoreBridge,
            props.onlyPhysical,
            props.onlyStatic
          );
          const vlans = response.data.interfaces
            .map((inter) => inter.vlan)
            .flat();
          items = items.concat(vlans);
          setItems(items.map((iface) => iface.name).sort());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    props.deviceId,
    props.ignoreBridge,
    props.onlyPhysical,
    props.onlyStatic,
  ]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    if (props.showModal && props.initialSelected)
      setSelectedItems(props.initialSelected);
  }, [props.showModal, props.initialSelected]);

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.onHide}
        size="lg"
        centered
        dialogClassName="modal-shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="FIREWALL.INTERFACE" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-3">
          <TableLoader rows={10} visible={loading} />
          <NoDataDisplay visible={!loading && items.length <= 0} />
          <BeSafeContainer visible={!loading && items.length > 0}>
            <div className="modal-selection-list overflow-auto">
              <Table className="mb-0 table-borderless" hover>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="border-bottom"
                        onClick={() => selectItem(item)}
                      >
                        <div className="d-flex justify-content-between">
                          <Form.Group className="mb-0 d-inline-block">
                            <Form.Check
                              id={item}
                              className="mb-0"
                              type="checkbox"
                            >
                              <Form.Check.Input
                                type="checkbox"
                                isValid
                                value={selectedItems.includes(item)}
                                checked={selectedItems.includes(item)}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => selectItem(item)}
                                disabled={props.disabledValues?.includes(item)}
                              />
                              <Form.Check.Label
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Lan />
                                {item}
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </BeSafeContainer>
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="transparent" onClick={props.onHide}>
            <FormattedMessage id="OBJECT_MODAL.CANCEL" />
          </Button>
          <BeSafeButton
            variant="primary"
            type="button"
            onClick={handleSubmit}
            text={<FormattedMessage id="GENERAL.SAVE" />}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InterfaceListSelect;
