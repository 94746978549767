import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import LayoutNavbar from 'shared/layouts/LayoutNavbar';
import LayoutSidebar from 'shared/layouts/LayoutSidebar';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import ConfirmDialog from 'components/ConfirmDialog';

import { SidebarDataProvider } from 'contexts/SidebarDataContext';

const Layout = (props) => {
  const intl = useIntl();

  const { showSidebarSecondary, concurrentUsersExceeded } = useSelector(
    ({ configuration }) => ({
      showSidebarSecondary: configuration.secondarySidebar,
      concurrentUsersExceeded: configuration.concurrentUsersExceeded,
    }),
    shallowEqual
  );

  const concurrentUsersExceededModal = (
    <BeSafeContainer visible={concurrentUsersExceeded}>
      <ConfirmDialog
        title={intl.formatMessage({
          id: 'GENERAL.WARNING',
        })}
        description={intl.formatMessage({
          id: 'REMOTE_ACCESS.CONCURRENT_USERS_WARNING',
        })}
        okLabel={intl.formatMessage({
          id: 'GENERAL.OK',
        })}
      />
    </BeSafeContainer>
  );

  return (
    <SidebarDataProvider>
      <div className={showSidebarSecondary ? 'sidebar-expanded' : ''}>
        {concurrentUsersExceededModal}

        <div className="menu-navbar">
          <LayoutSidebar {...props} />
        </div>
        <div className="content-layout">
          <div className="content-layout-header">
            <LayoutNavbar {...props} />
          </div>
          <div className="content-layout-body">
            {React.cloneElement(props.children, {
              user: props.user,
            })}
          </div>
        </div>
      </div>
    </SidebarDataProvider>
  );
};

export default Layout;
